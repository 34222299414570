import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Dialog, DialogContent, Grid, IconButton, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import Layout from '../../components/v2/Layout';
import { CloseOutlined } from '@material-ui/icons';
import { MONTHS, renderDateTime } from '../../helpers/date';
import { navigate } from 'gatsby';
import { getEmoriMonthly, getEmoriRelationMonthly } from '../../apis/emori';
import MonthYearSelect from '../../components/MonthYearSelect';
import { setEmoriDate } from '../../actions/emori';
import { getUrlParams } from '../../helpers/location';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    height: '100%',
    display: 'inline-block',
    backgroundColor: 'white',
    borderRadius: '10px',
    padding: '0 10px 0 11px',
  },
  month: {
    color: 'white',
    fontSize: '18px',
    fontWeight: 'bold',
    paddingBottom: '8px',
  },
  tableCellList: {
    border: 0,
    padding: '3px',
  },
  table: {
    maxWidth: '100%',
    marginBottom: '10px',
  },
  tableCell: {
    padding: '1px 10px 0 10px',
    border: 0
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'left',
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#3D6AAE',
  },
  selectedEmoriIcon: {
    marginTop: '9px',
    width: theme.spacing(14.75),
    height: theme.spacing(14.625),
  },
  feelingChoose: {
    fontSize: '17px',
    fontWeight: 'bold',
    color: '#3D6AAE',
  },
  closeTable: {
    padding: '1px 10px 0 10px',
    border: 0,
    display: 'flex',
    justifyContent: 'right',
  },
  dateList: {
    borderColor: '#C6EAF6',
    border: '1px solid',
    borderRadius: '10px',
    // padding: '10px 4px 0px 10px',
    marginBottom: '3px',
  },
  date: {
    padding: '5px 0 0 10px',
  },
  dateText: {
    fontSize: '15px',
  },
  view: {
    padding: '0 6px',
    float: 'right',
  },
}))

export default ({ location }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { month, year } = useSelector(state => state.emori);
  const [ open, setOpen ] = React.useState(false);
  const [ selected, setSelected ] = React.useState(null);
  const [ emoriList, setEmoriList] = React.useState(null);
  const [ limit, setLimit ] = React.useState('5');
  const params = getUrlParams(location.search);
  const { member, room } = params;

  React.useEffect(() => {
    emori();
  }, [month, year]);

  const emori = async () => {
    try {
      let res = {};
      if (member !== 'undefined' || room !== 'undefined') {
        res = await getEmoriRelationMonthly(room, member, year, month);
      } else {
        res = await getEmoriMonthly(year, month, limit);
      }
      const sortedKeys = Object.keys(res[Object.keys(res)]).sort((a, b) => Number(b) - Number(a));
      const sortedJson = {};
      sortedKeys.forEach(key => {
        sortedJson[Number(key)] = res[Object.keys(res)][key].map(item => ({ ...item }));
      });
      setEmoriList(sortedJson);
    } catch (e) {}
  }

  const onClickEmori = (emoji) => {
    setOpen(true);
    setSelected(emoji);
  }

  const onClickViewMore = (day) => {
    navigate('/emori/summaryDate/', { state: { day: day, room: room, member: member} });
  }

  return (
    <Layout bg='bg3' title='month'>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth='md' PaperProps={{ style: {borderRadius: '29px', marginBottom: '5px'}}}>
        <DialogContent>
          {
            selected ?
              <Table className={classes.table} size='small'>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.tableCell}>
                      <Typography className={classes.dialogTitle}>{renderDateTime(selected.created)}</Typography>
                    </TableCell>
                    <TableCell className={classes.closeTable}>
                      <IconButton aria-label='close' onClick={() => setOpen(false)}>
                        <CloseOutlined />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='center' colSpan={2} className={classes.tableCell}>
                      <img className={classes.selectedEmoriIcon} src={`${selected.emori.image}`} alt={`${selected.emori.name}`}/>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align='center' colSpan={2} className={classes.tableCell}>
                      <p className={classes.feelingChoose}>{selected.note}</p>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            : <></>
          }
        </DialogContent>
      </Dialog>
      <Typography gutterBottom style={{color:'white', fontSize: '18px'}}>EMORI สมุดบันทึกอารมณ์ของฉัน</Typography>
      <div className={classes.paper}>
        <MonthYearSelect />
        {
          emoriList && Object.values(emoriList).map((val, index) => {
            return (
              <div className={classes.dateList} key={index}>
                <div className={classes.view}>
                  <Typography variant='caption' onClick={() => onClickViewMore(Object.keys(emoriList)[index])} >view more</Typography>
                </div>
                <div className={classes.date}>
                  <Typography variant='caption' className={classes.dateText}>{Object.keys(emoriList)[index]} {MONTHS['full'][month-1]} {year}</Typography>
                </div>
                <Table style={{ tableLayout: 'fixed' }}>
                  <TableBody>
                    <TableRow>
                      {
                        val && val[0] ? (
                          <TableCell align='center' className={classes.tableCellList} onClick={() => onClickEmori(val[0])}>
                            <img style={{ width: '35px'}} src={val[0].emori.image} alt='profile_img' />
                          </TableCell>
                        ) : <TableCell align='center' className={classes.tableCellList}><img style={{ width: '35px'}} /></TableCell>
                      }
                      {
                        val && val[1] ? (
                          <TableCell align='center' className={classes.tableCellList} onClick={() => onClickEmori(val[1])}>
                            <img style={{ width: '35px'}} src={val[1].emori.image} alt='profile_img' />
                          </TableCell>
                        ) : <TableCell align='center' className={classes.tableCellList}><img style={{ width: '35px'}} /></TableCell>
                      }
                      {
                        val && val[2] ? (
                          <TableCell align='center' className={classes.tableCellList} onClick={() => onClickEmori(val[2])}>
                            <img style={{ width: '35px'}} src={val[2].emori.image} alt='profile_img' />
                          </TableCell>
                        ) : <TableCell align='center' className={classes.tableCellList}><img style={{ width: '35px'}} /></TableCell>
                      }
                      {
                        val && val[3] ? (
                          <TableCell align='center' className={classes.tableCellList} onClick={() => onClickEmori(val[3])}>
                            <img style={{ width: '35px'}} src={val[3].emori.image} alt='profile_img' />
                          </TableCell>
                        ) : <TableCell align='center' className={classes.tableCellList}><img style={{ width: '35px'}} /></TableCell>
                      }
                      {
                        val && val[4] ? (
                          <TableCell align='center' className={classes.tableCellList} onClick={() => onClickEmori(val[4])}>
                            <img style={{ width: '35px'}} src={val[4].emori.image} alt='profile_img' />
                          </TableCell>
                        ) : <TableCell align='center' className={classes.tableCellList}><img style={{ width: '35px'}} /></TableCell>
                      }
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            )
          })
        }
      </div>
    </Layout>
  )
}